<template>
  <div>
    <el-tabs v-model="activeName" @tab-click="handleClick" v-loading="loading">
      <el-tab-pane label="经销商设置" name="first">
        <el-form :model="formData" :rules="checkFormDataRules" ref="formData">
          <el-form-item
              label="待审会员有效期(过期死点)"
              :label-width="formLabelWidth"
              prop="distributor_level1_valid_period" required style="text-align: left"
          >
            <el-input type="number" v-model="formData.distributor_level1_valid_period" autocomplete="off"  style="width: 300px">
              <template slot="append">天</template>
            </el-input>
          </el-form-item>
          <el-form-item
              label="优惠顾客有效期(过期冻结)"
              :label-width="formLabelWidth"
              prop="distributor_level2_valid_period" required style="text-align: left"
          >
            <el-input type="number" v-model="formData.distributor_level2_valid_period" autocomplete="off"  style="width: 300px">
              <template slot="append">月</template>
            </el-input>
          </el-form-item>
          <el-form-item
              label="超级优惠顾客有效期(过期冻结)"
              :label-width="formLabelWidth"
              prop="distributor_level3_valid_period" required style="text-align: left"
          >
            <el-input type="number" v-model="formData.distributor_level3_valid_period" autocomplete="off"  style="width: 300px">
              <template slot="append">月</template>
            </el-input>
          </el-form-item>
          <el-form-item
              label="白金卡有效期(过期冻结)"
              :label-width="formLabelWidth"
              prop="distributor_level4_valid_period" required style="text-align: left"
          >
            <el-input type="number" v-model="formData.distributor_level4_valid_period" autocomplete="off"  style="width: 300px">
              <template slot="append">月</template>
            </el-input>
          </el-form-item>
          <el-form-item
              label="钻石卡有效期(过期冻结)"
              :label-width="formLabelWidth"
              prop="distributor_level5_valid_period" required style="text-align: left"
          >
            <el-input type="number" v-model="formData.distributor_level5_valid_period" autocomplete="off"  style="width: 300px">
              <template slot="append">月</template>
            </el-input>
          </el-form-item>
          <el-form-item
              label="至尊卡有效期(过期冻结)"
              :label-width="formLabelWidth"
              prop="distributor_level6_valid_period" required style="text-align: left"
          >
            <el-input type="number" v-model="formData.distributor_level6_valid_period" autocomplete="off"  style="width: 300px">
              <template slot="append">月</template>
            </el-input>
          </el-form-item>
        </el-form>
      </el-tab-pane>
<!--      <el-tab-pane label="国家/地区配置" name="second">国家/地区配置</el-tab-pane>-->
<!--      <el-tab-pane label="直销商配置" name="third">角色管理</el-tab-pane>-->
<!--      <el-tab-pane label="定时任务补偿" name="fourth">定时任务补偿</el-tab-pane>-->
    </el-tabs>
    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="onSubmit('formData')"
      >保存配置</el-button>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'MemberSettings',
    data() {
      return {
        loading: false,
        activeName: 'first',
        formLabelWidth: "300px",
        formData:{
          distributor_level1_valid_period: 0,
          distributor_level2_valid_period: 0,
          distributor_level3_valid_period: 0,
        },
        checkFormDataRules: {
          distributor_level1_valid_period: [
            { required: true, message: "请设置待审会员有效期"},
          ],
          distributor_level2_valid_period: [
            { required: true, message: "请设置优惠顾客有效期"},
          ],
          distributor_level3_valid_period: [
            { required: true, message: "请设置超级优惠顾客有效期"},
          ],
          distributor_level4_valid_period: [
            { required: true, message: "请设置白金卡有效期"},
          ],
          distributor_level5_valid_period: [
            { required: true, message: "请设置钻石卡有效期"},
          ],
          distributor_level6_valid_period: [
            { required: true, message: "请设置至尊卡有效期"},
          ]
        },
      }
    },
    methods: {
      handleClick(tab, event) {
        console.log(tab, event);
      },

      onSubmit(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            //验证通过执行请求
            this.loading = true;
            this.myfun
              .request('adminSystemSetMemberConfig.api', this.formData, "post")
              .then((result) => {
                if (result.code == "1") {
                  this.$message({
                    message: result.msg,
                    type: "success",
                  });
                } else {
                  this.$message({
                    message: result.msg,
                    type: "warning",
                  });
                }
                this.loading = false;

              });
          } else {
            console.log("error submit!!");
            return false;
          }
        });
      },

      getConfig(){
        this.loading = true;
        this.myfun
          .request('adminSystemSetMemberConfigDeputy.api', this.formData, "post")
          .then((result) => {
            if (result.code == "1") {
              this.formData = result.data;
            } else {
              this.$message({
                message: result.msg,
                type: "warning",
              });
            }
            this.loading = false;
          });
      }
    },
    created() {
      this.getConfig();
    }

  }
</script>

<style scoped>

</style>
