<template>
  <div>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="推荐网络" name="first" v-loading="loading">
        <el-tabs tab-position="right" >
          <el-tab-pane label="树形图">
            <el-form :inline="true" ref="recommendFormData" :model="recommendFormData" class="demo-form-inline" style="text-align: left">
              <el-form-item >
                <el-input  placeholder="请输入内容" v-model="recommendFormData.keywords" class="input-with-select">
                  <el-select class="el-select" v-model="recommendFormData.searchField" slot="prepend" placeholder="请选择">
                    <el-option label="手机号" value="2">手机号</el-option>
                    <el-option label="昵称" value="1">昵称</el-option>
                    <!--                    <el-option label="身份证号" value="3">身份证号</el-option>-->
                  </el-select>
                </el-input>
              </el-form-item>
              <el-form-item >
                <el-button type="primary" @click="onSubmit('recommendFormData')"
                >查询下级</el-button
                >
              </el-form-item>
              <el-form-item >
                <el-button type="primary" @click="getRecommentLine()"
                >查询上级</el-button
                >
              </el-form-item>
            </el-form>
            <div v-if="recommentLineList.length > 0">
              <ul class="lineList">
                <li>上级推荐列表:</li>
                <li v-for="(item, index) in recommentLineList" key="index">[#{{ item.uid }} | {{ item.nickname }} | {{ item.phone }}]</li>
              </ul>
            </div>
            <el-tree
                :props="props"
                :load="loadRecommendNode"
                :lazy="loadRecommendNodeLazy"
                :expand-on-click-node="false"

            >
              <span style="font-size: 14px" slot-scope="{ node, data }">
                 │ #{{data.uid}} │ {{data.nickname}} │ {{data.phone}} │ {{data.my_level_str}} │ 第{{data.layer}}层 │  个人累计 {{data.personalPv}}  │  个人总累计 {{data.personalTotalPv}} │ 团队累计 {{data.totalPv}} │ 累计A {{data.totalAPv}} │ 累计B {{data.totalBPv}} │





                <!--                                <el-button-->
                <!--                                    type="text"-->
                <!--                                    size="mini"-->
                <!--                                    @click.native="() => addOrder(data)">-->
                <!--                                                  新增订单-->
                <!--                                </el-button>-->

                <!--                                <el-button-->
                <!--                                    type="text"-->
                <!--                                    size="mini"-->
                <!--                                    @click="() => append(data)">-->
                <!--                                  修改推荐人-->
                <!--                                </el-button>-->
                <!--                                <el-button-->
                <!--                                    type="text"-->
                <!--                                    size="mini"-->
                <!--                                    @click="() => remove(node, data)">-->
                <!--                                  修改安置人-->
                <!--                                </el-button>-->


              </span>
            </el-tree>
          </el-tab-pane>
          <el-tab-pane label="结构图">结构图</el-tab-pane>
        </el-tabs>
      </el-tab-pane>
    </el-tabs>



    <!-- Form -->

    <el-dialog
        title="新增订单"
        :visible.sync="addOrderDialogFormVisible"
        v-loading="loading"
    >
      <el-form :model="addOrderFormData" :rules="checkAddOrderFormDataRules" ref="addOrderFormData">
        <el-form-item
            label="订单类型"
            :label-width="formLabelWidth"
            prop="orderTypeId" style="text-align: left"
        >
          <el-select v-model="addOrderFormData.orderTypeVal" placeholder="请选择">
            <el-option
                v-for="item in addOrderFormData.orderTypeList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
            label="PV"
            :label-width="formLabelWidth"
            prop="total_pv" required
        >
          <el-input v-model="addOrderFormData.total_pv" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item
            label="金额"
            :label-width="formLabelWidth"
            prop="pay_amount" required
        >
          <el-input v-model="addOrderFormData.pay_amount" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item
            label="支付时间"
            :label-width="formLabelWidth"
            prop="pay_time" required style="text-align: left"
        >
          <el-date-picker
              v-model="addOrderFormData.pay_time" :default-value="new Date()"
              type="datetime"
              placeholder="选择日期时间">
          </el-date-picker>
        </el-form-item>


      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addOrderDialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="doAddOrder('addOrderFormData')"
        >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import myfun from "../../util/myfun";

export default {
  name: 'MemberNetSearch',
  components: {
  },
  data() {
    return {
      loading:true,
      logLoading: true,
      loadRecommendNodeLazy: true,
      loadParentNodeLazy: false,
      props: {
        label: 'name',
        children: 'zones',
        isLeaf: 'leaf'
      },
      activeName: 'first',

      //推荐人搜索框
      recommendFormData: {
        searchField: '2',
        keywords: '',
        node:{},
        resolve:{},
      },

      //安置人搜索框
      parentFormData: {
        searchField:'1',
        keywords: '',
        node:{},
        resolve:{},
      },

      formData: {
        page: 1,
        pagesize: 10,
        total: 0
      },

      tableData: [],



      addOrderDialogFormVisible: false,
      formLabelWidth: '120px',
      addOrderFormData: {

      },
      checkAddOrderFormDataRules: {
        full_name: [
          { required: true, message: "请输入会员姓名"},
        ],
        mobile: [
          { required: true, message: "请输入手机号"},
        ]
      },
      recommentLineList:[]
    }
  },
  methods: {
    loadRecommendNode(node, resolve) {
      let id = typeof(node.data) !== 'undefined' ? node.data.uid : 0;
      if(id === 0){
        //加载数据的方法里把node，reslove存起来
        this.recommendFormData.node = node;
        this.recommendFormData.resolve = resolve;
        this.recommendFormData.node.childNodes = [];
      }
      let level = node.level;
      this.myfun.request('adminMemberGetRecommendChildrenList.api', {id: id, level: level, keywords: this.recommendFormData.keywords, searchField: this.recommendFormData.searchField}, 'post').then((result) => {
        if (result.code == '1') {
          let childrenList = result.data.list;
          for (let x in childrenList){
            if(childrenList[x].leaf == '1'){
              childrenList[x].leaf = true;
            }
          }
          this.loading = false;
          return resolve(childrenList);
        } else {
          this.$message({
            message: result.msg,
            type: 'warning'
          });
        }

        this.loading = false;
      });
    },
    loadParentNode(node, resolve) {
      if(!this.loadParentNodeLazy){
        //加载数据的方法里把node，reslove存起来
        this.parentFormData.node = node;
        this.parentFormData.resolve = resolve;
        this.parentFormData.node.childNodes = [];
        return;
      }
      let id = typeof(node.data) !== 'undefined' ? node.data.id : 0;
      let level = node.level;
      this.myfun.request('adminMemberGetParentChildrenList.api', {id: id, level: level, keywords: this.parentFormData.keywords, searchField: this.parentFormData.searchField}, 'post').then((result) => {
        if (result.code == '1') {
          let childrenList = result.data.list;
          for (let x in childrenList){
            if(childrenList[x].leaf == '1'){
              childrenList[x].leaf = true;
            }
          }
          this.loading = false;
          return resolve(childrenList);
        } else {
          this.$message({
            message: result.msg,
            type: 'warning'
          });
        }
        this.loading = false;
      });
    },
    handleClick(tab, event) {
      // console.log(tab.label, tab.index)
      // console.log(tab, event);
      if(tab.index == '1'){
        //手动调用加载数据方法
        if(!this.loadParentNodeLazy){
          this.loading = true;
          this.loadParentNodeLazy = true;
          this.loadParentNode(this.parentFormData.node,this.parentFormData.resolve);
        }

      }

      if(tab.index === '2'){
        this.getMemberNetModifyLog();
      }
    },
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.formData.pagesize = val;
      this.getMemberNetModifyLog();
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.formData.page = val;
      this.getMemberNetModifyLog();
    },
    getMemberNetModifyLog(){
      this.logLoading = true;
      this.myfun.request('adminMemberGetMemberNetModifyLog.api', this.formData, 'post').then((result) => {
        if (result.code == '1') {
          this.tableData = result.data.list;
        } else {
          this.$message({
            message: result.msg,
            type: 'warning'
          });
        }
        this.formData.total = parseInt(result.data.total);
        this.logLoading = false;
      });
    },
    //重载查询树形图
    onSubmit(formName){
      this.loading = true;
      if(formName === 'parentFormData'){
        this.parentFormData.node.childNodes = [];
        this.loadParentNode(this.parentFormData.node,this.parentFormData.resolve);
      }else if(formName === 'recommendFormData'){
        this.recommendFormData.node.childNodes = [];
        this.loadRecommendNode(this.recommendFormData.node,this.recommendFormData.resolve);
      }
    },

    resetForm(formName) {
      this.$nextTick(()=>{
        this.$refs[formName].resetFields();
      })
    },
    resetFormData(formName){
      this[formName] = {
        member_id: 0,
        total_pv: 500,
        pay_amount: 500,
        pay_time: new Date(),
        orderTypeVal: '首购单',
        orderTypeList: [{
          value: '1',
          label: '重消单'
        }, {
          value: '2',
          label: '首购单'
        }, {
          value: '3',
          label: '升级单'
        }, {
          value: '4',
          label: '体验包'
        }, {
          value: '5',
          label: '超级体验包'
        }, {
          value: '6',
          label: '全年重消单'
        }, {
          value: '7',
          label: '体外单'
        }, {
          value: '8',
          label: '激活单'
        }, {
          value: '9',
          label: '续约单'
        }],
      };

      console.log()
      // this.addOrderFormData.pay_time = '';
    },


    //新增订单
    addOrder(row) {
      this.resetFormData('addOrderFormData');
      this.addOrderFormData.member_id = row.id;
      // this..member_level = row.member_level;
      // this..uname = row.uname;
      // this..full_name = row.full_name;
      // this..mobile = row.mobile;
      // this..identity_number = row.identity_number;
      // this..status = row.status;
      // this..is_black = row.is_black;
      // this..is_leader = row.is_leader;
      // this..password = '';
      // console.log(row)
      this.addOrderDialogFormVisible = true;
    },

    //执行新增订单
    doAddOrder(formName){
      this.$refs[formName].validate((valid) => {
        if (valid) {
          //验证通过执行请求
          this.loading = true;
          // this.addOrderFormData.pay_time = this.myfun.utc2beijing(this.addOrderFormData.pay_time);
          this.myfun
              .request('adminOrderAddTestOrder.api', this.addOrderFormData, "post")
              .then((result) => {
                if (result.code == "1") {
                  this.$message({
                    message: result.msg,
                    type: "success",
                  });
                  this.addOrderDialogFormVisible = false;
                } else {
                  this.$message({
                    message: result.msg,
                    type: "warning",
                  });
                }
                this.loading = false;
              });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    getRecommentLine() {

      this.recommentLineList = [];
      if (this.recommendFormData.keywords !== "") {
        this.myfun.request(
            "adminMemberGetRecommendParentsLineList.api",
            {
              searchField: this.recommendFormData.searchField,
              keywords: this.recommendFormData.keywords,
            },
            "post"
        )
            .then((res) => {
              if (res.code == "1") {
                this.recommentLineList = res.data.line_rs;
              }
            });
      }
    },
  },
  created() {
    // let date = new Date();

    // this.addOrderFormData.pay_time=date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate() + ' ' + date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds();
    // console.log(this.addOrderFormData.pay_time);
    // console.log('aaa',date);

  },
  mounted() {
  },
  computed:{
    pageSizs(){
      return this.$store.state.pageSizes;
    }
  }
}
</script>

<style scoped>
.el-select {
  width: 110px;
}
.input-with-select .el-input-group__prepend {
  background-color: #fff;
}



.lineList {
  display: flex;
  padding-left: 0;
  color: #bb0000;
  font-size: 0.9rem;
}
.lineList li {
  list-style: none;
  padding: 0 5px;
}
.lineList li:nth-child(n + 3):before {
  content: " ▶ ";
}
</style>
