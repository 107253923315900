<template>
  <div>
    <el-form :inline="true" ref="formData" :model="formData" class="demo-form-inline" style="text-align: left">
      <el-form-item >
        <el-select class="el-select" v-model="formData.myLevel"  placeholder="经销商级别">
          <el-option label="所经销商级别" value="-1">经销商级别</el-option>
          <el-option label="非经销商" value="0">非经销商</el-option>
          <el-option label="V1" value="1">V1</el-option>
          <el-option label="V2" value="2">V2</el-option>
          <el-option label="V3" value="3">V3</el-option>
          <el-option label="V4" value="4">V4</el-option>
          <el-option label="V5" value="5">V5</el-option>
          <el-option label="V6" value="6">V6</el-option>
        </el-select>
      </el-form-item>
      <el-form-item >
        <el-select class="el-select" v-model="formData.is_head"  placeholder="团长级别">
          <el-option label="团长级别" value="-1">团长级别</el-option>
          <el-option label="非团长" value="0">非团长</el-option>
          <el-option label="团长" value="1">团长</el-option>
          <el-option label="经理" value="2">经理</el-option>
          <el-option label="总监" value="3">总监</el-option>
          <el-option label="董事" value="4">董事</el-option>
          <el-option label="联创" value="5">联创</el-option>
          <el-option label="合伙人" value="6">合伙人</el-option>
        </el-select>
      </el-form-item>
      <el-form-item >
          <el-input  placeholder="请输入内容" v-model="formData.keywords" class="input-with-select">
            <el-select class="el-select" v-model="formData.searchField" slot="prepend" placeholder="请选择">
              <el-option label="手机号" value="2">手机号</el-option>
              <el-option label="昵称" value="1">昵称</el-option>
              <el-option label="代理区域" value="10">代理区域</el-option>
<!--              <el-option label="身份证号" value="3">身份证号</el-option>-->
            </el-select>
          </el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSearch('formData')">查询</el-button>
      </el-form-item>
    </el-form>
    <el-table
        v-loading="loading"
        :data="tableData"
        stripe
        style="width: 100%" max-height="750">
      <el-table-column
          prop="uid"
          label="#ID" width="100px"
          >
      </el-table-column>
      <el-table-column
          prop="member_info"
          label="会员信息" width="300px"
          >
        <template slot-scope="scope">
          <span v-html="
          '昵称：'+scope.row.nickname+'<br />'+
          '手机号码：'+scope.row.phone
"></span>
        </template>
      </el-table-column>
      <el-table-column
          prop="member_info"
          label="团长级别" width="100px"
      >
        <template slot-scope="scope">

          <span v-if="scope.row.is_head == '1'" v-html="
          '团长'"></span>
          <span v-else-if="scope.row.is_head === '2'" v-html="
          '经理'"></span>
          <span v-else-if="scope.row.is_head === '3'" v-html="
          '总监'"></span>
          <span v-else-if="scope.row.is_head === '4'" v-html="
          '董事'"></span>
          <span v-else-if="scope.row.is_head === '5'" v-html="
          '联创'"></span>
          <span v-else-if="scope.row.is_head === '6'" v-html="
          '合伙人'"></span>
          <span v-else v-html="
          '非团长'
"></span>
        </template>
      </el-table-column>
      <el-table-column
          prop="my_level_str"
          label="经销商级别" width="200px"
      >
      </el-table-column>
      <el-table-column
          prop="province_str"
          label="省级代理区域" width="200px"
      >
      </el-table-column>
      <el-table-column
          prop="area_str"
          label="市级代理区域" width="200px"
      >
      </el-table-column>


      <el-table-column
          prop="recommend_info"
          label="推荐人" width="350px"
      >
        <template slot-scope="scope">

          <span v-if="scope.row.recommend_info !== ''" v-html="
          '昵称：'+scope.row.recommend_info.nickname+'<br />'+
          // '真实姓名：'+scope.row.recommend_info.phone+'<br />'+
          '经销商级别：'+scope.row.recommend_info.my_level_str+'<br />'
"></span>
          <span v-else v-html="
          '-'
"></span>
        </template>
      </el-table-column>
      <el-table-column
          prop="incentive_points"
          label="激励金"
      >
      </el-table-column>
<!--      <el-table-column-->
<!--          prop="agent_level.name"-->
<!--          label="付费会员"-->
<!--      >-->
<!--      </el-table-column>-->
<!--      <el-table-column-->
<!--          prop=""-->
<!--          label="升级名次"-->
<!--      >-->
<!--        <template slot-scope="scope">-->

<!--          <span v-html="-->
<!--          // 'RM：'+scope.row.my_level1_rank+'<br />'+-->
<!--          'ASM：'+scope.row.my_level2_rank+'<br />'+-->
<!--          'MD：'+scope.row.my_level3_rank+'<br />'+'<br />'-->
<!--          // 'CEO：'+scope.row.my_level4_rank+'<br />'+'<br />'-->
<!--"></span>-->
<!--        </template>-->
<!--      </el-table-column>-->
      <el-table-column
          prop="last_time"
          label="登录时间"
          width="200">
      </el-table-column>
      <el-table-column
          prop="add_time"
          label="注册时间"
          width="200">
      </el-table-column>
      <el-table-column
          fixed="right"
          label="操作"
          width="100">
        <template slot-scope="scope">
          <el-button @click="editMember(scope.row)" type="text" size="small" v-if="myfun.checkAuthRule('adminMemberEditMember')">编辑会员</el-button>
          <el-button @click="addBonus(scope.row)" type="text" size="small" v-if="myfun.checkAuthRule('adminMemberAddBonus')">发放奖励</el-button>
<!--          <br />-->
<!--          <el-button @click="editNet(scope.row, 1)" type="text" size="small" v-if="myfun.checkAuthRule('adminMemberEditMemberRecommend') ">改推荐人</el-button><br />-->
<!--          <el-button @click="editNet(scope.row, 2)" type="text" size="small" v-if="myfun.checkAuthRule('adminMemberEditMemberParent') && scope.row.member_level === '3'">改安置人</el-button><br />-->
        </template>
      </el-table-column>
    </el-table>
    <div class="block">
      <el-pagination style="margin-top: 1rem"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="formData.page"
          :page-sizes="pageSizs"
          :page-size="formData.pagesize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="formData.total">
      </el-pagination>
    </div>





    <!-- Form -->

    <el-dialog
        title="编辑会员"
        :visible.sync="editMemberdialogFormVisible"
        v-loading="loading"
    >
      <el-form :model="editMemberformData" :rules="checkEditMemberformRules" ref="editMemberformData">
        <el-form-item
            label="昵称"
            :label-width="formLabelWidth"
            prop=""
        >
          <div style="text-align: left">{{editMemberformData.nickname}}</div>
        </el-form-item>
        <el-form-item
            label="手机号"
            :label-width="formLabelWidth"
            prop=""
        >
          <div style="text-align: left">{{editMemberformData.phone}}</div>
        </el-form-item>
        <el-form-item
            label="经销商级别"
            :label-width="formLabelWidth"
            prop=""
        >
          <el-select class="el-select" v-model="editMemberformData.my_level"  placeholder="经销商级别">
            <el-option label="非经销商" value="0">非经销商</el-option>
            <el-option label="V1" value="1">V1</el-option>
            <el-option label="V2" value="2">V2</el-option>
            <el-option label="V3" value="3">V3</el-option>
            <el-option label="V4" value="4">V4</el-option>
            <el-option label="V5" value="5">V5</el-option>
            <el-option label="V6" value="6">V6</el-option>
          </el-select>
        </el-form-item>
        <el-form-item
            label="是否团长"
            :label-width="formLabelWidth"
            prop="my_level2_rank">
          <el-select class="el-select" v-model="editMemberformData.is_head"  placeholder="团长级别">
            <el-option label="非团长" value="0">非团长</el-option>
            <el-option label="团长" value="1">团长</el-option>
            <el-option label="经理" value="2">经理</el-option>
            <el-option label="总监" value="3">总监</el-option>
            <el-option label="董事" value="4">董事</el-option>
            <el-option label="联创" value="5">联创</el-option>
            <el-option label="合伙人" value="6">合伙人</el-option>
          </el-select>
        </el-form-item>
        <el-form-item
            label="激励金"
            :label-width="formLabelWidth"
            prop="incentive_points">
          <el-input v-model="editMemberformData.incentive_points" autocomplete="off" style="width: 150px"></el-input>
        </el-form-item>
        <el-form-item
            label="省代理区域"
            :label-width="formLabelWidth"
            prop="province_str">
          <el-autocomplete
              v-model="editMemberformData.province_str"
              :fetch-suggestions="(queryString, cb) => {querySearchAsync(queryString, cb, 1)}"
              placeholder="请输入内容"
              @select="handleSelect"
          ></el-autocomplete>
        </el-form-item>
        <el-form-item
            label="市代理区域"
            :label-width="formLabelWidth"
            prop="area_str">
          <el-autocomplete
              v-model="editMemberformData.area_str"
              :fetch-suggestions="(queryString, cb) => {querySearchAsync(queryString, cb, 2)}"
              placeholder="请输入内容"
              @select="handleSelect"
          ></el-autocomplete>
        </el-form-item>

      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="editMemberdialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="doEditMember('editMemberformData')"
        >确 定</el-button>
      </div>
    </el-dialog>


    <el-dialog
        title="奖金发放"
        :visible.sync="addBonusdialogFormVisible"
        v-loading="loading"
    >
      <el-form :model="addBonusformData" :rules="checkAddBonusformRules" ref="addBonusformData">
        <el-form-item
          label="手机号"
          :label-width="formLabelWidth"
          prop=""
        >
          <div style="text-align: left">{{addBonusformData.phone}}</div>
        </el-form-item>
        <el-form-item
            label="昵称"
            :label-width="formLabelWidth"
            prop=""
        >
          <div style="text-align: left">{{addBonusformData.nickname}}</div>
        </el-form-item>

        <el-form-item
          label="奖金类型"
          :label-width="formLabelWidth"
          prop=""
        >
          <el-select class="el-select" v-model="addBonusformData.bonus_type"  placeholder="奖金类型">
            <el-option label="领导人帕点奖金" value="100">领导人帕点奖金</el-option>
            <el-option label="区(县)代区域补贴" value="50">区(县)代区域补贴</el-option>
            <el-option label="市代区域补贴" value="30">市代区域补贴</el-option>
            <el-option label="省代区域补贴" value="40">省代区域补贴</el-option>
            <el-option label="级差奖" value="8">级差奖</el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="订单编号"
          :label-width="formLabelWidth"
          prop="incentive_points">
          <el-input v-model="addBonusformData.order_id" autocomplete="off" style="width: 150px"></el-input>
        </el-form-item>
        <el-form-item
          label="奖金金额"
          :label-width="formLabelWidth"
          prop="incentive_points">
          <el-input v-model="addBonusformData.bonus" autocomplete="off" style="width: 150px"></el-input>
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="addBonusdialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="doAddBonus('addBonusformData')"
        >确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  export default {
    name: 'MemberList',
    components: {
    },
    data() {
      return {
        test: true,
        formData: {
          page: 1,
          pagesize: 10,
          total: 0,
          keywords: '',
          searchField: '2',
          myLevel: '-1',
          is_head: '-1'
        },
        editNetformData: {
          id: '0',
          uname: '',
          full_name: '',
          recommend_uname: '',
          parent_uname: '',
          net_postition: '左',
          type: '',
          member_level: '',
          remark: ''
        },
        editMemberformData: {
          uid: '0',
          nickname: '',
          phone: '',
          my_level: '',
          my_level2_rank: '',
          my_level3_rank: '',
          is_head: '0',
          incentive_points: '0',
          area_str: '',
          province_str: ''
        },
        addBonusformData: {
          uid: '0',
          nickname: '',
          phone: '',
          my_level: '',
          my_level2_rank: '',
          my_level3_rank: '',
          is_head: '0',
          incentive_points: '0',
          area_str: '',
          province_str: '',
          bonus_type: '',
          bonus: '',
          order_id: ''
        },
        checkEditNetformRules: {
          recommend_uname: [
            { required: true, message: "请输入推荐人编号"},
          ],
          parent_uname: [
            { required: true, message: "请输入安置人编号"},
          ]
        },
        checkEditMemberformRules: {
          full_name: [
            { required: true, message: "请输入会员姓名"},
          ],
          mobile: [
            { required: true, message: "请输入手机号"},
          ]
        },
        checkAddBonusformRules: {
        },
        tableData:[],
        loading: false,
        operateTitle: '修改推荐人',
        formLabelWidth: "120px",
        editNetdialogFormVisible:false,
        editMemberdialogFormVisible:false,


        addBonusdialogFormVisible:false,
      }
    },
    methods: {
      getList(page = 0){
        this.tableData = [];
        if(page > 0){
          this.formData.page = page;
        }
        this.loading = true;
        this.myfun.request('adminMemberGetMemberList.api', this.formData, 'post').then((result) => {
          if (result.code == '1') {
            this.tableData = result.data.list;
          } else {
            this.$message({
              message: result.msg,
              type: 'warning'
            });
          }
          this.formData.total = parseInt(result.data.total);
          this.loading = false;
        });
      },
      handleSizeChange(val) {
        // console.log(`每页 ${val} 条`);
        this.formData.pagesize = val;
        this.getList();
      },
      handleCurrentChange(val) {
        // console.log(`当前页: ${val}`);
        this.formData.page = val;
        this.getList();
      },
      editNet(row, type) {
        this.operateTitle = type === 1 ? '修改推荐人' : '修改安置人';
        this.editNetformData.type = type;
        this.editNetformData.member_level = row.member_level;
        this.editNetformData.id = row.id;
        this.editNetformData.uname = row.uname;
        this.editNetformData.full_name = row.full_name;

        if(type === 1){
          this.editNetformData.parent_uname = '111111';
          this.editNetformData.net_postition = '左';
        }else if(type === 2){
          this.editNetformData.recommend_uname = '111111';
        }

        if(row.recommend_info !== ''){
          this.editNetformData.recommend_uname = row.recommend_info.uname
        }

        if(row.parent_info !== ''){
          this.editNetformData.parent_uname = row.parent_info.uname;
          this.editNetformData.net_postition = row.net_postition_str;
        }




        this.editNetdialogFormVisible = true;
      },
      addBonus(row) {
        this.addBonusformData.uid = row.uid;
        this.addBonusformData.nickname = row.nickname;
        this.addBonusformData.phone = row.phone;
        this.addBonusdialogFormVisible = true;
      },
      editMember(row) {
        this.editMemberformData.uid = row.uid;
        this.editMemberformData.nickname = row.nickname;
        this.editMemberformData.my_level = row.my_level;
        this.editMemberformData.phone = row.phone;
        this.editMemberformData.my_level2_rank = row.my_level2_rank;
        this.editMemberformData.my_level3_rank = row.my_level2_rank;
        this.editMemberformData.is_head = row.is_head;
        this.editMemberformData.incentive_points = row.incentive_points;
        this.editMemberformData.area_str = row.area_str;
        this.editMemberformData.province_str = row.province_str;
        this.editMemberdialogFormVisible = true;
      },
      doEditMember(formName){
        this.$refs[formName].validate((valid) => {
          if (valid) {
            //验证通过执行请求
            this.loading = true;
            this.myfun
              .request('adminMemberEditMember.api', this.editMemberformData, "post")
              .then((result) => {
                if (result.code == "1") {
                  this.getList();
                  this.$message({
                    message: result.msg,
                    type: "success",
                  });
                  this.editMemberdialogFormVisible = false;
                } else {
                  this.$message({
                    message: result.msg,
                    type: "warning",
                  });
                }
                // this.editMemberdialogFormVisible = false;
                this.loading = false;
              });
          } else {
            console.log("error submit!!");
            return false;
          }
        });
      },
      doAddBonus(formName){
        this.$refs[formName].validate((valid) => {
          if (valid) {
            //验证通过执行请求
            this.loading = true;
            this.myfun
              .request('adminMemberAddBonus.api', this.addBonusformData, "post")
              .then((result) => {
                if (result.code == "1") {
                  this.getList();
                  this.$message({
                    message: result.msg,
                    type: "success",
                  });
                  this.addBonusdialogFormVisible = false;
                } else {
                  this.$message({
                    message: result.msg,
                    type: "warning",
                  });
                }
                // this.editMemberdialogFormVisible = false;
                this.loading = false;
              });
          } else {
            console.log("error submit!!");
            return false;
          }
        });
      },
      onSubmit(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            //验证通过执行请求
            this.loading = true;
            let apiName = this.editNetformData.type === 1 ? 'adminMemberEditMemberRecommend.api' : 'adminMemberEditMemberParent.api';
            this.myfun
              .request(apiName, this.editNetformData, "post")
              .then((result) => {
                if (result.code == "1") {
                  this.getList();
                  this.$message({
                    message: result.msg,
                    type: "success",
                  });
                  this.editNetdialogFormVisible = false;
                } else {
                  this.$message({
                    message: result.msg,
                    type: "warning",
                  });
                }
                this.loading = false;
              });
          } else {
            console.log("error submit!!");
            return false;
          }
        });
      },
      onSearch(){
        this.getList(1);
      },
      changeMemberStatus(value, id, index){
        //验证通过执行请求
        this.loading = true;
        this.myfun
          .request('adminMemberEditMember.api', {id:id, statusIndex:index, status:value, isModifyMemberStatus: 1}, "post")
          .then((result) => {
            if (result.code == "1") {
              this.$message({
                message: result.msg,
                type: "success",
              });
            } else {
              this.$message({
                message: result.msg,
                type: "warning",
              });
            }
            this.loading = false;
          });
      },
      querySearchAsync(queryString, cb, type) {
        console.log(type)
        this.myfun
            .request('commonPubGetOrderAddressAreaList.api', {keywords: queryString, type: type}, "post")
            .then((result) => {
              if (result.code == "1") {
                cb(result.data);
              }
            });


      },
      createStateFilter(queryString) {
        return (state) => {
          return (state.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
        };
      },
      handleSelect(item) {
        console.log(item);
      }
    },
    created() {
      this.getList(1);
    },
    mounted() {
    },
    computed:{
      pageSizs(){
        return this.$store.state.pageSizes;
      }
    }
  }
</script>

<style>
  .el-select {
    width: 150px;
  }
  .input-with-select .el-input-group__prepend {
    background-color: #fff;
  }


  /* switch按钮样式 */
  .switch.custom .el-switch__label {
    position: absolute;
    display: none;
    color: #fff !important;
  }
  /*打开时文字位置设置*/
  .switch.custom .el-switch__label--right {
    z-index: 1;
  }
  /* 调整打开时文字的显示位子 */
  .switch.custom .el-switch__label--right span{
    margin-left: 5px;
  }
  /*关闭时文字位置设置*/
  .switch.custom .el-switch__label--left {
    z-index: 1;
  }
  /* 调整关闭时文字的显示位子 */
  .switch.custom .el-switch__label--left span{
    margin-left: 20px;
  }
  /*显示文字*/
  .switch.custom .el-switch__label.is-active {
    display: block;
  }
  /* 调整按钮的宽度 */
  .switch.custom.el-switch .el-switch__core,
  .el-switch .el-switch__label {
    width: 82px !important;
    margin: 0;
  }
</style>
